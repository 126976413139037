.app {
  font-family: "Avenir";
  font-weight: 300;
  color: white;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #F5F5F5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.nftapp {
  background: #F5F5F5;
  font-family: "Avenir LT Std";
  font-weight: 300;
  color: white;
  /* box-shadow: 1px 1px 15px #000000; */
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
  -webkit-border: 20px !important;
  -moz-border: 20px !important;
  border: 20px !important;}

@-webkit-keyframes AnimationName {
0%{background-position:0% 50%}
50%{background-position:100% 50%}
100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
0%{background-position:0% 50%}
50%{background-position:100% 50%}
100%{background-position:0% 50%}
}
@keyframes AnimationName {
0%{background-position:0% 50%}
50%{background-position:100% 50%}
100%{background-position:0% 50%}
}

.connectbutton {
  background: #D4388D;
  /* box-shadow: 0px 1px 15px #4ac0e7; */
  color: #ffffff;
  border-radius: 100px;
  padding: 5px 12px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 120%;
}

.navbarfont {
  color: #D53790 !important;
  font-family: "Avenir";
  font-weight: 800;
}

.navbar-header{
  text-align:center;
  color: #ffffff !important;

}

.navbar-toggle {
  float: none;
  margin-right:0;
}

.light{
  color:white !important;
}

.nftminter {
  background: #F5F5F5;
  font-family: "Avenir LT Std";
  font-weight: 300;
  color: white;
  /* box-shadow: 1px 1px 15px #4ac0e7; */
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
  -webkit-border: 20px !important;
  -moz-border: 20px !important;
  border: 20px !important;
}

.nftstaker {
  background: linear-gradient(90deg, rgba(246, 251, 253, 0.5) 0%, rgba(247, 238, 247, 0.5) 100%);;
  font-family: "Avenir LT Std";
  font-weight: 300;
  color: white;
  /* box-shadow: 1px 1px 15px #4ac0e7; */
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
  -webkit-border: 20px !important;
  -moz-border: 20px !important;
  border: 20px !important;
}

.stakingrewards {
  background: #F5F5F5;
    font-family: "Avenir LT Std";
    font-weight: 300;
    /* box-shadow: 1px 1px 15px #4ac0e7; */
    /* background: radial-gradient(circle at 50% 50%, #000000, #050604, #0a0c08, #0f130b, #14190f, #191f13, #1e2517, #232b1b, #28321e, #2d3822, #323e26); */
}

.modal-style1 {
  background: #F5F5F5;
  font-family: "Avenir LT Std";
  font-weight: 300;
  color: white;
  /* box-shadow: 1px 1px 15px #4ac0e7; */
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
  -webkit-border: 20px !important;
  -moz-border: 20px !important;
  border: 20px !important;
}

.container-style {
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch
}

@keyframes glow2 {
  from {
    box-shadow: 0 0 4px -1px #f4dc76;
  }
  to {
    box-shadow: 0 0 10px 7px #ffb300;
  }
}

.collectionmenu {
    font-family: "Avenir";
    font-weight: 800;
    font-size: 20px;
    line-height: 120%;
}

.rewardsmetabadges {
  font-family: "Avenir";
  font-weight: 800;
  font-size: 15px;
  line-height: 120%;
}

.errorsearch {
  background: rgb(255, 255, 255);
}

.navbarglow {
  /* box-shadow: 1px 1px 15px #4ac0e7; */
  background: radial-gradient(circle at 50% 30%, #000000, #000000, #000000, #000000);
  font-family: "Avenir LT Std";
  font-weight: 300;
  color: white;
  /* box-shadow: 1px 1px 15px #4ac0e7; */
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
  -webkit-border: 20px !important;
  -moz-border: 20px !important;
  border: 20px !important;
}
.farm {
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(90,92,106,1) 0%, rgba(32,45,58,1) 81.3% );
  font-family: "Avenir LT Std";
  font-weight: 300;
  color: rgb(255, 255, 255);
  /* box-shadow: 0 0 1px 1px #ffffff; */
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 20px !important;
    -webkit-border: 20px !important;
    -moz-border: 20px !important;
    border: 20px !important;
}
.farmcard {
  color: rgb(255, 255, 255);
  font-family: "Avenir LT Std";
  font-weight: 300;
  background-image: linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% );
  }
  .mbgtitlestyle {
    color: #ffffff;
    font-family: "Avenir LT Std";
    font-weight: 300;
    background: #161616;
    background: radial-gradient(circle at 50% 30%, #000000, #000000, #000000, #000000);
    font-family: "Avenir LT Std";
    font-weight: 300;
    color: white;
    /* box-shadow: 1px 1px 15px #4ac0e7; */
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 20px !important;
    -webkit-border: 20px !important;
    -moz-border: 20px !important;
    border: 20px !important;
  }
  
  .nftportal {
    background: #F5F5F5;
    background: radial-gradient(circle at 50% 30%, #000000, #000000, #000000, #000000);
    font-family: "Avenir LT Std";
    font-weight: 300;
    color: white;
    /* box-shadow: 1px 1px 15px #4ac0e7; */
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 20px !important;
    -webkit-border: 20px !important;
    -moz-border: 20px !important;
    border: 20px !important;
  }
  
  .nft-card {
    background-image: #F5F5F5;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 120%;
    border: 1px;
    border-color: #4ac1e8;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: #4ac1e8;
    /* or 28px */
    display: flex;
    align-items: left;
    color: #252F5C;
    /* box-shadow: 1px 1px 15px #4ac0e7; */
    /* -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 10px !important;
    -webkit-border: 20px !important;
    -moz-border: 20px !important;
    border: 20px !important; */
  }

  .stakedcard {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
      font-size: 23px;
      line-height: 120%;
      /* or 28px */
      display: flex;
      align-items: left;
      background: linear-gradient(90deg, #D4388D 0%, #6BA1CD 96.14%);
      background-size: 400% 400%;
      -webkit-animation: AnimationStake 14s ease infinite;
      -moz-animation: AnimationStake 14s ease infinite;
      animation: AnimationStake 14s ease infinite;
      color: #FFFFFF;
      /* box-shadow: 1px 1px 11px #FFD700; */
    }
  
  @-webkit-keyframes AnimationStake {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes AnimationStake {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @keyframes AnimationStake {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }

  .col-centered{
    float: none;
    margin: 0 auto;
  }
  
  .col-center-block {
    float: none;
    display: block;
    margin: 0 auto;
    margin-left: auto; margin-right: auto;
    margin-top: auto; margin-bottom: auto;
  }

  .wrap-table {
  border-collapse:collapse;
  table-layout:fixed;
  width:310px;
}

  .wrap-nft {
    word-wrap: break-word;
    table-layout: fixed;
    margin-left: auto;
    margin-right: auto;
}

.center {
text-align: center
}

.progress-title{
  font-family: "Avenir";
  font-size: 18px;
  font-weight: 400;
  color: #252F5C;
  letter-spacing: 2px;
  margin: 0 0 15px;
  border-radius: 100px;
  box-sizing: border-box;
}

.progress {
  align-items: center;
  animation: fill 80s;
  width: 500px;
  border-radius: 100px;
  box-sizing: border-box;
  /* box-shadow: 1px 1px 15px #4ac0e7; */
  /* background: radial-gradient(circle at 50% 30%, #000000, #000000, #000000, #000000); */
}
 
.progress-bar {
  height: 18px;
  border-radius: 100px;
  box-sizing: border-box;
}

.progress-striped .progress-bar {
  background-color: #D4388D; 
  width: 100%; 
  border-radius: 100px;
  box-sizing: border-box;
  border: 1px solid #D4388D;
  background-image: linear-gradient(
        45deg, #D4388D 25%, 
        transparent 25%, transparent 50%, 
        #D4388D 50%, #D4388D 75%,
        transparent 75%, transparent); 
  animation: progressAnimationStrike 10s;
}


@keyframes load {
  100% { width: 100; }
  100% { width: 100%; }
}
@keyframes fill {
  0% { width: 0; }
  100% { width: 100%; }
}


.MStakingP {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 120%;
  /* or 50px */
  /* display: flex; */
  align-items: center;
  color: #252F5C;
}

.img-text-container {
  position: relative;
  /* display: flex; */
  /* height: 100vh; */
  /* width: 100vw;  */
  /* align-items: center; */
  /* justify-content: center; */
  /* padding-left: 10px;
  padding-right: 10px; */
}

.inner {
  position: absolute;
  top: 65%;
  left: 50%;
  /* width: 100%; */
  margin-right: -50%;
  transform: translate(-50%, -50%);
  /* background-color: rgba(0, 0, 0, 0.3); */
  color: #fff;
  padding: 2px;
  font-family: 'Avenir';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 120%;
/* or 20px */
/* display: flex;
align-items: center; */
}

.mergediv{
display: inline-block;
}

.my-image {
  margin-right: 20px;
}
.my-image img {
    border-radius: 10px;
}

.loading-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #f5f5f5;
}